import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/targets';

export default {
  getTargets(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getTarget(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  deleteTarget(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  createTarget(target) {
    return axios.post(`${baseUrl}`, target);
  },
  updateTarget(uid, targetDto) {
    return axios.put(`${baseUrl}/${uid}`, targetDto);
  },
  patchTarget(uid, targetDto) {
    return axios.patch(`${baseUrl}/${uid}`, targetDto);
  },
};
