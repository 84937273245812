<style lang="scss">
  .update-product-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-product-modal">
    <section class="mb-2">
      <b-form-group
        label="Version"
        label-for="update-product-version"
      >
        <b-form-input id="update-product-version"
                      v-model="product.version"
                      required
                      class="mb-1" placeholder="Enter Version*"
        />
      </b-form-group>
      <b-alert v-model="showVersionErrors" variant="danger" dismissible>
        <div class="alert-body">
          <span>{{ versionError }}</span>
        </div>
      </b-alert>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateProduct()"
      >Update Product</b-button>
    </section>

  </div>
</template>

<script>
  import ProductService from '@/services/ProductService'

  export default {
    components: {
    },
    props: {
      productExisting: {
        required: true,
        type: Object,
      },
      targetUid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        product: {
          version: '',
        },
        versionError: '',
      };
    },
    computed: {
      showVersionErrors() {
        return this.versionError !== ''
      },
    },
    mounted() {
      this.setProductFromExisting();
    },
    methods: {
      setProductFromExisting() {
        const {
          version, product, vendor, vendor_product_id,
        } = this.productExisting;
        const productProduct = {
          version, product, vendor, vendor_product_id,
        };
        this.product = productProduct;
      },
      closeModals() {
        this.$bvModal.hide('modal-update-product');
      },
      updateProduct() {
        ProductService.updateProduct(this.targetUid, this.productExisting.uid, this.product).then(() => {
          this.$toast.success(`Updated product ${this.product.product} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
          this.closeModals();
        }).catch(error => {
          this.versionError = error.response.data.error
        });
      },
      checkFormValid() {
        const version = this.product.version.length > 0;
        return version;
      },
    },
  };
</script>
