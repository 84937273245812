import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/issues';

export default {
  getIssues(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getIssue(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  getTargetIssues(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}&target_uid=${uid}`);
  },
  getProductIssues(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}?product_uid=${uid}`);
  },
  deleteIssue(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  updateIssue(uid, targetDto) {
    return axios.put(`${baseUrl}/${uid}`, targetDto);
  },
  patchIssue(uid, targetDto) {
    return axios.patch(`${baseUrl}/${uid}`, targetDto);
  },
};
