var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issues-page"},[_c('b-card',{staticClass:"mb-0 mt-4",attrs:{"no-body":""}},[_c('div',{staticClass:"card-header"},[_c('div',[_c('h4',{staticClass:"card-title"},[_vm._v("Issues")])])]),_c('section',[_c('b-table',{staticClass:"data-table",attrs:{"hover":"","items":_vm.issues,"fields":_vm.headers,"current-page":_vm.currentPage,"per-page":0},scopedSlots:_vm._u([{key:"cell(subject)",fn:function(data){return [_c('router-link',{staticClass:"font-weight-bold d-block text-nowrap text-underline",attrs:{"to":{ name: 'issue-view', params: { uid: data.item.uid } }}},[_vm._v(" "+_vm._s(data.item.target_name)+" "+_vm._s(data.item.subject)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"badge badge-pill",class:{
            'badge-danger' : data.item.status === 'open',
            'badge-warning' : data.item.status === 'ignored',
            'badge-success' : data.item.status === 'closed',
          }},[_vm._v(" "+_vm._s(data.item.status)+" ")])]}},{key:"cell(issue_type)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.issue_type)+" ")]}},{key:"cell(severity)",fn:function(data){return [_c('span',{staticClass:"badge badge-pill",class:{
            'badge-primary' : data.item.severity === 'LOW',
            'badge-warning' : data.item.severity === 'MEDIUM',
            'badge-danger' : data.item.severity === 'HIGH',
            'badge-dark' : data.item.severity === 'CRITICAL',
          }},[_vm._v(" "+_vm._s(data.item.severity)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{staticClass:"m-0",attrs:{"variant":"link","right":"","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}],null,true)},[(_vm.$can('Update', 'Issue'))?_c('b-dropdown-item-button',{staticClass:"full-nested-btn",on:{"refresh":function($event){return _vm.refreshIssues()},"click":function($event){return _vm.showIssueModal(data.item, 'modal-update-issue')}}},[_vm._v(" Update Issue ")]):_vm._e()],1)]}}])}),_c('b-row',[(_vm.total_count > _vm.filters.page_size)?_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.total_count,"per-page":10},on:{"change":_vm.getMoreIssues},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),(_vm.total_count === 0)?_c('b-col',{staticClass:"ml-2 mt-1"},[_c('p',[_vm._v("No results")])]):_vm._e()],1)],1)]),_c('b-modal',{attrs:{"id":"modal-update-issue","title":"Update issue","size":"lg","hide-footer":""}},[(_vm.selectedIssue)?_c('update-issue',{attrs:{"uid":_vm.selectedIssue.uid,"issue-existing":_vm.selectedIssue},on:{"refresh":function($event){return _vm.refreshIssues()},"close":function($event){return _vm.closeModals()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }