<style lang="scss">
  .update-product-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-issue-modal">
    <!--    <section class="mb-2">
      <b-form-group
        label="Note"
        label-for="update-product-note"
      >
        <b-form-textarea
          id="update-product-note"
          v-model="issue.note"
          required
          class="mb-1"
          placeholder="Enter Note*"
          rows="3"
        />
      </b-form-group>
    </section>-->

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button v-if="issue.status !== 'ignored'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                variant="secondary" @click="ignoreIssue()"
      >Ignore Issue</b-button>
      <b-button v-if="issue.status === 'ignored'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                variant="secondary" @click="unIgnoreIssue()"
      >Un-Ignore Issue</b-button>
      <b-button v-if="issue.status !== 'closed'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                variant="danger" @click="closeIssue()"
      >Close Issue</b-button>
      <b-button v-if="issue.status === 'closed'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                variant="success" @click="openIssue()"
      >Re-Open Issue</b-button>
    </section>

  </div>
</template>

<script>
  import IssueService from '@/services/IssueService'

  export default {
    components: {
    },
    props: {
      issueExisting: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        issue: {
          ignored: '',
          closed: '',
          status: '',
          description: '',
          note: '',
        },
      };
    },
    mounted() {
      this.setIssueFromExisting();
    },
    methods: {
      setIssueFromExisting() {
        const {
          closed, description, ignored, note, subject, status,
        } = this.issueExisting;
        const productProduct = {
          closed, description, ignored, note, subject, status,
        };
        this.issue = productProduct;
      },
      closeModals() {
        this.$bvModal.hide('modal-update-issue');
        this.$bvModal.hide('modal-add-note');
      },
      ignoreIssue() {
        IssueService.patchIssue(this.issueExisting.uid, { ignored: true, notes: this.issue.note }).then(() => {
          this.$toast.success('Ignored issue Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.closeModals();
          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not ignore issue, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      unIgnoreIssue() {
        IssueService.patchIssue(this.issueExisting.uid, { ignored: false, notes: this.issue.note }).then(() => {
          this.$toast.success('Ignored issue Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not ignore issue, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      closeIssue() {
        IssueService.patchIssue(this.issueExisting.uid, { closed: true, notes: this.issue.note }).then(() => {
          this.$toast.success('Closed issue Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
          this.closeModals();
        }).catch(() => {
          this.$toast.error('Could not close issue, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      openIssue() {
        IssueService.patchIssue(this.issueExisting.uid, { closed: false, notes: this.issue.note }).then(() => {
          this.$toast.success('Closed issue Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
          this.closeModals();
        }).catch(() => {
          this.$toast.error('Could not close issue, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      addNote() {
        IssueService.patchIssue(this.issueExisting.uid, { notes: this.issue.note }).then(() => {
          this.$toast.success('Note Added Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
          this.closeModals();
        }).catch(() => {
          this.$toast.error('Could not add note, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        if (typeof this.issue.note !== 'undefined') {
          const note = true;
          return note;
        }
        return true;
      },
    },
  };
</script>
