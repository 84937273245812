import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/targets';
const productsUrl = '/products';

export default {
  getProducts(targetUid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${targetUid}${productsUrl}${queryString}`);
  },
  getProduct(targetUid, uid) {
    return axios.get(`${baseUrl}/${targetUid}${productsUrl}/${uid}`);
  },
  deleteProduct(targetUid, uid) {
    return axios.delete(`${baseUrl}/${targetUid}${productsUrl}/${uid}`);
  },
  createProduct(targetUid, product) {
    return axios.post(`${baseUrl}/${targetUid}${productsUrl}`, product);
  },
  updateProduct(targetUid, uid, targetDto) {
    return axios.put(`${baseUrl}/${targetUid}${productsUrl}/${uid}`, targetDto);
  },
};
