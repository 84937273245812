<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Activity')" :class="{'disabled': loading}">
    <b-card-actions action-refresh action-collapse @refresh="getMoreActivity(1)" title="Activities" ref="refreshCard" no-body>
      <b-card-body>
        <b-card-sub-title>This table shows the changes performed through the Portal</b-card-sub-title>
      </b-card-body>
      <section>
        <b-table
          class="data-table"
          hover
          :items="activity"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>
          <template #cell(user)="data">
            <router-link :to="{ name: 'user-view', params: { uid: data.item.user.uid } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.user.name }}
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreActivity" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 mx-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card-actions>
  </div>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import ActivityService from '../../services/ActivityService';

  export default {
    name: 'ModelActivities',
    components: {
      BCardActions,
    },
    props: {
      title: {
        required: false,
        type: String,
        default: 'Activities',
      },
      model: {
        required: true,
        type: String,
      },
      uid: {
        required: true,
        type: String,
      },
      noBody: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        headers: [
          {
            key: 'created_at',
            label: 'Date',
          },
          {
            key: 'message',
            label: 'Message',
          },
          {
            key: 'user',
            label: 'Updated By',
          },
        ],
        activity: [],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
        },
        total_count: 0,
      };
    },
    mounted() {
        this.getActivity();
    },
    methods: {
      getMoreActivity(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getActivity();
      },
      getActivity() {
        this.$refs.refreshCard.showLoading = true
        ActivityService.getModelActivity(this.filters, this.model, this.uid).then(res => {
          this.activity = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(() => {
          this.$toast.error('Could not get activity, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          setTimeout(() => {
            this.$refs.refreshCard.showLoading = false
          }, 100)
        });
      },
    },
  };
</script>
