<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="targets-page">

    <div class="row">
      <div class="col-md-6 col-xl-6">
        <div class="card card-congratulation-medal">
          <div class="card-body">
            <b-dropdown class="m-0" style="float: right"  variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Target')"
                                      @click="showTargetModal('modal-update-target')">
                Update Target
              </b-dropdown-item-button>
            </b-dropdown>
            <h5>{{ target.name }} | {{ target.target }}</h5>
            <p class="card-text font-small-3"> Added {{ target.created_at | formatDateTime  }} </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-6">
        <div class="card card-congratulation-medal">
          <div class="card-body">
            <h5>Notification Group</h5>
            <span id="notification-group-tooltip" class="badge badge-pill badge-primary">{{  group.name }}</span>
            <b-tooltip target="notification-group-tooltip" placement="bottom" triggers="hover">
              <div v-for="item in group.users">{{ item.name }} {{ item.email }}</div><br>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="card-header">
          <h4 class="card-title">Products</h4>
          <section class="d-flex align-items-center justify-content-end">
            <b-button v-if="$can('Create', 'Product')" @click="$bvModal.show('modal-add-product')" class="btn-success pull-right"><span class="text-nowrap">Add Product</span></b-button>
          </section>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="products"
        :fields="headers"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(actions)="data">
          <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Product')"
                                    @click="showProductModal(data.item, 'modal-update-product')">
              Update Product
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Product')"
                                    @click="showProductModal(data.item, 'modal-delete-product')">
              Delete Product
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="total_count > filters.page_size"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="filters.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >

              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <issues-comp
      v-if="target"
      :uid="this.uid"
      :type="'target'"
      title="">
    </issues-comp>

    <div class="d-flex mt-2">
      <div class="ui-spacer"></div>
    </div>
    <model-activities :no-body="true" model="target" :uid="this.uid"></model-activities>

    <b-modal id="modal-add-product" title="Add new product" size="lg" hide-footer>
      <add-product :target="this.target" @refresh="refreshProducts()" @close="closeModals()"></add-product>
    </b-modal>
    <b-modal id="modal-update-product" title="Update product" size="lg" hide-footer>
      <update-product v-if="selectedProduct" :targetUid="this.target.uid" :product-existing="selectedProduct" @refresh="refreshProducts()" @close="closeModals()"></update-product>
    </b-modal>
    <b-modal id="modal-delete-product" title="Delete product" hide-footer>
      <delete-modal
        v-if="selectedProduct"
        @close="closeModals()" @delete="deleteProduct(selectedProduct)"
        :subtitle="selectedProduct.name"
        title="Are you sure you wish to delete product"></delete-modal>
    </b-modal>
    <b-modal id="modal-delete-target" title="Delete target" hide-footer>
      <delete-modal
        v-if="target"
        @close="closeModals()" @delete="deleteTarget(target)"
        :subtitle="target.name"
        title="Are you sure you wish to delete target"></delete-modal>
    </b-modal>
    <b-modal id="modal-update-target" title="Update Target" hide-footer>
      <update-target v-if="target" :uid="uid" :target-existing="target" @refresh="refreshTarget()" @close="closeModals()"></update-target>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import ProductService from '@/services/ProductService';
import NotificationGroupsService from '@/services/NotificationGroupsService';
import UpdateTarget from '@/views/targets/viewTarget/sections/UpdateTarget.vue'
import DeleteModal from '../../../components/modals/DeleteModal.vue';
import AddProduct from './sections/AddProduct.vue';
import UpdateProduct from './sections/UpdateProduct.vue';
import TargetService from '../../../services/TargetService';
import IssuesComp from '../../../components/Issues-comp.vue';
import ModelActivities from '../../activities/ModelActivities.vue';

export default {
  name: 'Target',
  components: {
    AddProduct,
    DeleteModal,
    IssuesComp,
    UpdateProduct,
    ModelActivities,
    UpdateTarget,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedProduct: null,
      loading: true,
      target: {
        uid: '',
        name: '',
        target: '',
        notification_group_uid: '',
        created_at: '',
      },
      products: [],
      group: {
        uid: '',
        name: '',
        description: '',
        users: [],
      },
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      targetArr: {
        target_uid: '',
      },
      total_count: 0,
      headers: [
        {
          key: 'vendor',
          label: 'Vendor',
        },
        {
          key: 'product',
          label: 'Product',
        },
        {
          key: 'version',
          label: 'Version',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    };
  },
  mounted() {
    this.getTarget();
    this.getProducts();
  },
  methods: {
    refreshProducts() {
      this.closeModals();
      this.getProducts();
    },
    refreshTarget() {
      this.closeModals();
      this.getTarget();
    },
    getGroup(notificationGroupUid) {
      if (typeof (notificationGroupUid) !== 'undefined') {
        NotificationGroupsService.getGroup(notificationGroupUid)
          .then(res => {
            this.group.name = res.data.name
            this.group.description = res.data.description
            this.group.uid = res.data.uid
            this.group.users = res.data.users
          })
          .finally(() => {
            this.loading = true;
          });
      }
    },
    closeModals() {
      this.$bvModal.hide('modal-add-product');
      this.$bvModal.hide('modal-update-target');
      this.$bvModal.hide('modal-delete-product');
    },
    getTarget() {
      this.loading = true;
      TargetService.getTarget(this.uid).then(res => {
        this.target = {
          name: res.data.name,
          uid: res.data.uid,
          notification_group_uid: res.data.notification_group_uid,
          target: res.data.target,
          created_at: res.data.created_at,
        }
        this.getGroup(this.target.notification_group_uid);
        this.targetArr.target_uid = this.target.uid
      }).catch(() => {
        this.$toast.error('Could not get target, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
      });
    },
    saveProduct(product) {
      this.loading = true;
      ProductService.updateProduct(this.uid, product.uid, {
        version: product.version, product: product.product, vendor: product.vendor, vendor_product_id: product.vendor_product_id,
      }).then(() => {
      }).catch(() => {
        this.$toast.error('Could not get target, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    showProductModal(product, modalName) {
      this.setSelectedProduct(product);
      this.$bvModal.show(modalName);
    },
    setSelectedProduct(target) {
      this.selectedProduct = target;
    },
    getProducts() {
      this.loading = true;
      ProductService.getProducts(this.uid, this.filters).then(res => {
        this.products = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(() => {
        this.$toast.error('Could not get products, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    deleteProduct(product) {
      ProductService.deleteProduct(this.target.uid, product.uid).then(() => {
        this.$toast.success(`Deleted product ${product.vendor} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.refreshProducts();
      }).catch(() => {
        this.$toast.error('Could not delete target, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    showTargetModal(modalName) {
      this.$bvModal.show(modalName);
    },
    deleteTarget(target) {
      TargetService.deleteTarget(target.uid).then(() => {
        this.$toast.success(`Deleted target ${target.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$router.push({ name: 'targets' })
      }).catch(() => {
        this.$toast.error('Could not delete target, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
  },
};
</script>
