<style lang="scss">
.add-product-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
thead {
  position: sticky;
  width: 100%;
}
</style>

<template>
  <div class="add-product-modal">
    <section class="mb-2">
      <div class="row">
        <div class="col-md-6">
          <b-form-input v-model="search.vendor"
                        required
                        class="mb-1" placeholder="Vendor"
                        @keyup="getVendorProducts"
                        @change="getVendorProducts"
          />
        </div>
        <div class="col-md-6">
          <b-form-input v-model="search.product"
                        required
                        class="mb-1" placeholder="Product"
                        @keyup="getVendorProducts"
                        @change="getVendorProducts"
          />
        </div>
      </div>
    </section>
    <section class="mb-2">
      <b-table
        ref="refProductListTable"
        class="table-responsive"
        :items="products"
        :fields="headers"
        primary-key="id"
        show-empty
        width="100vw"
        empty-text="Start typing to find your product."
      >
        <template #cell(product)="data">
          <span v-b-tooltip.hover :title="data.item.product">
            {{ data.item.product.length > 59 ? data.item.product.substring(0, 60) + '...' : data.item.product }}
          </span>

        </template>
        <template #cell(actions)="data">
          <b-button v-if="$can('Update', 'Target')" style="padding:0.2rem;" class="btn btn-icon rounded-circle btn-success btn-sm"
                    @click="addTargetProduct(data.item)"
          >
            <i class="fa fa-plus" />
          </b-button>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>
  import VendorProductSearchService from '@/services/VendorProductSearchService';
  import ProductService from '../../../../services/ProductService';

  export default {
    components: {},
    props: {
      target: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        showPassword: false,
        search: {
          vendor: '',
          product: '',
        },
        products: [],
        product: {
          vendor: '',
          product: '',
          version: '',
          id: '',
        },
        headers: [
          {
            key: 'vendor',
            label: 'Vendor',
            class: 'w-50',
          },
          {
            key: 'product',
            label: 'Product',
            class: 'w-50',
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'text-right',
          },
        ],
        filters: {
          search: '',
        },
      };
    },
    mounted() {
    },
    methods: {
      getVendorProducts() {
        this.filters.search = `${this.search.vendor} ${this.search.product}`;
        VendorProductSearchService.getVendorProducts(this.filters, this.search.vendor, this.search.product).then(res => {
          this.products = res.data.data;
        }).catch(() => {
          this.$toast.error('Could not find Product, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      addTargetProduct(data) {
        this.product.product = data.product;
        this.product.vendor = data.vendor;
        this.product.version = data.version;
        this.product.vendor_product_id = data.id;
        ProductService.createProduct(this.target.uid, this.product).then(() => {
          this.$toast.success(`Added Product ${this.product.product} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh');
        }).catch(() => {
          this.$toast.error('Could not add Product, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
      },
    },
  };
</script>
